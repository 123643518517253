import React from "react"
import Blog from "../components/Blog"

import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import Head from "../components/utils/Head"
export default function BlogPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/blogs" />
      </Helmet>
      <Head
        metaTitle="Visit Our Blog for Tips on Custom Development - CodeFulcrum"
        title="Solutioning In Software Development"
        desc="Explore our blog for the latest insights on custom software development, product design, and data engineering. Stay ahead with expert tips and industry trends"
      />
      <Blog />
    </Layout>
  )
}
