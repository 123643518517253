export const substring = (text, limit) => {
  const spreadString = text.length > limit ? "..." : ""
  const trimedText = text.substring(0, limit)
  return `${trimedText}${spreadString}`
}

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result.split(",")[1])
    reader.onerror = error => reject(error)
  })

export const sendMail = body => {
  return fetch(`https://flux.codefulcrum.com/api/send-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
}

export const getfileExtention = (fileName = "test.pdf") => {
  const fileNameParts = fileName.split(".")
  const fileExtension = fileNameParts[fileNameParts.length - 1]
  return fileExtension
}

export async function fileToAttachmentFormat(file, name, type = "file") {
  if (!file) {
    return null
  }
  if (!name) {
    name = file.name
  }
  const fileExtension = getfileExtention(file.name)
  const fileString = await toBase64(file)
  return {
    filename: `${name}-${type}.${fileExtension}`,
    type: file.type,
    disposition: "attachment",
    content: fileString,
  }
}
