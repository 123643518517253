import React from "react"
import Hero from "./Hero/Hero"
import Content from "./Content"
function Blog() {
  return (
    <div>
      <Hero
        header="The latest ideas from experts in digital enterprise technology"
        text="CodeFulcrum is a software development company located in USA and Pakistan. We are committed to developing innovative software solutions for Startups, SMEs and Enterprises all across the globe, helping them grow their businesses using latest technology solutions."
      />
      <Content />
    </div>
  )
}

export default Blog
