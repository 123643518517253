import React from "react"
import Grid from "@mui/material/Grid"
import "./Blog.scss"
import Card from ".././shared/BlogCard"
import cardData from "../shared/Data/blogs"
import { Link } from "gatsby"

function Content() {
  const latestBlog = cardData[cardData.length - 1]
  return (
    <div className="container">
      <div className="blog-display">
        <div className="first-blog-wrapper">
          <Link
            to="/blogs/what-is-a-great-product-development-strategy"
            style={{ textDecoration: "none" }}
          >
            <Grid container spacing={4} justifyContent="center">
              <Grid item md={6}>
                <img
                  src={latestBlog.img}
                  alt="blog-1"
                  className="header-blog-img"
                  loading="lazy"
                />
              </Grid>
              <Grid item md={6}>
                <p className="date">{latestBlog.date}</p>
                <p className="header">{latestBlog.header}</p>
                <p className="text">{latestBlog.text}</p>
              </Grid>
            </Grid>
          </Link>
        </div>

        <div className="card-wrapper">
          <Card cardData={cardData} col={4} gap={4} skipFirst />
        </div>
      </div>
    </div>
  )
}

export default Content
